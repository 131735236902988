<template>
  <div>
    <div class="navbar">
      <div class="navbar_container">
        <img
            src="@/assets/images/Logo-oscuro.png"
            loading="lazy"
            alt=""
            class="nav_logo"
        />
        <div>
          <a
              @click="dialog = true"
              class="button nav hide-on-mobile _2 w-button"
          >Revisar multa recibida</a>
          <a @click="changeDrawer" class="button nav hide-on-mobile w-button"
          >Solicitar información</a>
        </div>
      </div>
    </div>
    <br>
    <div data-w-id="42d0e2d9-7422-6b8a-e006-eec239b01bc2" class="section hero wf-section">   
      <div class="containerhtml">
        <div class="hero_wrap">
          <div class="hero_text-wrap">
            <div class="overflow-hidden">
              <h1 class="h1">
                <span
                    data-w-id="a9a00d89-8475-e028-bf03-441bc87c75e8"
                    class="inline"
                >PIM Comercio</span
                ><br>
                             </h1>
              <h2 >
                <span
                    data-w-id="22a92833-99ce-918a-a977-132974c9587b"
                    class="inline"
                >Fiscaliza en terreno las </span
                >
                <span
                    data-w-id="e323a66e-615f-7ee9-9d1e-ea3920ea25fd"
                    class="inline"
                > patentes Municipales.</span
                >
              </h2>              
            </div>
            <div
                data-w-id="ed9aca52-1fc2-04df-00f9-121bf589f4f3"
                class="paragraph hero" style="text-align: justify; "
            >
              Inspectores en terreno, dotados de tecnología de punta,
              podrán fiscalizar de mejor manera las faltas cometidas por el
              comercio respecto de el estado de sus patentes comerciales actualizadas.
              Con esta herramienta podrán emitir multas directamente desde el
              lugar en que se estén incumpliendo las normativas Municipales.
            </div>
            <div
                data-w-id="ac011676-ae63-7c4d-245a-84ee509a08b5"
                class="w-form"
            >

              <button class="button black form w-button" @click="changeDrawer">
                Solicitar información
              </button>
              <!-- </form> -->
              <!-- <div class="success-message w-form-done">
                <div>Gracias, nos comunicaremos a la bevedad.</div>
              </div> -->
              <!-- <div class="error-message w-form-fail">
                <div class="error-text">
                  Por favor, ingrese un correo válido.
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      

      <div
          data-w-id="f55479d3-1163-9317-5f9d-7f34bf162c27"
          class="hero_illustration"
      >
        <div class="containerhtml large absolute">
          <img
              src="@/assets/images/mlb-back-2.png"
              loading="eager"
              srcset="
              @/assets/images/mlb-back-2-p-500.png   500w,
             @/assets/images/mlb-back-2-p-800.png   800w,
             @/assets/images/mlb-back-2-p-1080.png 1080w"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 58vw, (max-width: 1439px) 65vw, 875px"
              alt=""
              class="illustration _4"
          />
         
        </div>
      </div>
    </div>

    <div data-w-id="42d0e2d9-7422-6b8a-e006-eec239b01bc2" class="section hero wf-section">   
      <div class="containerhtml">
        <div class="hero_wrap">
          <div class="hero_text-wrap">
            <div class="overflow-hidden">
              <h1 class="h1">
                <span
                    data-w-id="a9a00d89-8475-e028-bf03-441bc87c75e8"
                    class="inline"
                >PIM Tránsito</span
                >
              </h1>
              <h2 >
                <span
                    data-w-id="22a92833-99ce-918a-a977-132974c9587b"
                    class="inline"
                >Optimiza la gestión de los inspectores</span
                >
                <span
                    data-w-id="e323a66e-615f-7ee9-9d1e-ea3920ea25fd"
                    class="inline"
                >Municipales en materia de tránsito.</span
                >
              </h2>                 
            </div>
            <div
                data-w-id="ed9aca52-1fc2-04df-00f9-121bf589f4f3"
                class="paragraph hero" style="text-align: justify; "
            >
              Cada inspector Municipal que esté utilizando PIM, podrá multar a los
              conductores de vehículos que hayan cometido una falta de tránsito o 
              encuentren mal estacionados impidiendo la libre circulación del 
              parque vehicular dentro de los límites del Municipio.
            </div>
            <div
                data-w-id="ac011676-ae63-7c4d-245a-84ee509a08b5"
                class="w-form"
            >

              <button class="button black form w-button" @click="changeDrawer">
                Solicitar información
              </button>
            </div>
          </div>
        </div>
      </div>

      
      <img
          src="@/assets/images/Hand-back-3.png"
          loading="eager"
          data-w-id="6ca79db6-a405-5a88-537a-451e18365f75"
          sizes="100vw"
          srcset="
          @/assets/images/Hand-back-3-p-500.png   500w,
          @/assets/images/Hand-back-3-p-800.png   800w,
          @/assets/images/Hand-back-3-p-1080.png 1080w,
          @/assets/images/Hand-back-3.png        1186w
        "
          alt=""
          class="hero_mobile-image"
      />
      <div
          data-w-id="f55479d3-1163-9317-5f9d-7f34bf162c27"
          class="hero_illustration"
      >
        <div class="containerhtml large absolute">
          <img
              src="@/assets/images/Hand-back-2.png"
              loading="eager"
              srcset="
              @/assets/images/Hand-back-2-p-500.png   500w,
             @/assets/images/Hand-back-2-p-800.png   800w,
             @/assets/images/Hand-back-2-p-1080.png 1080w"
              sizes="(max-width: 767px) 100vw, (max-width: 991px) 58vw, (max-width: 1439px) 65vw, 875px"
              alt=""
              class="illustration _4"
          />
        </div>
      </div>
    </div>
    <div class="section-3 wf-section">
      <div
          style="padding-top: 56.17021276595745%"
          class="video w-video w-embed"
      >
        <iframe
            class="embedly-embed"
            src="https://cdn.embedly.com/widgets/media.html?src=https://www.youtube.com/embed/Lc1CcXHPOX0?rel=0&enablejsapi=1&feature&oembed&display_name=YouTube&url=https://www.youtube.com/watch?v&Lc1CcXHPOX0&rel=0&enablejsapi=1&image=https://i.ytimg.com/vi/Lc1CcXHPOX0/hqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text/html&schema=youtube"
            scrolling="no"
            allowfullscreen=""
            title="Publicidad Click2Call"
        ></iframe>
      </div>
    </div>
    <div
        data-w-id="9dfb54bc-b731-aadf-74e1-61637e5a5532"
        class="section backgroud-3 wf-section"
    >
      <div class="containerhtml">
        <div class="feature_wrap">
          <div class="w-layout-grid feature_grid">
            <div
                id="w-node-_9dfb54bc-b731-aadf-74e1-61637e5a5536-0568580c"
                class="_480"
            >
              <h2 class="h2 how">CUAL ES EL PROBLEMA A SOLUCIONAR. </h2>
              <div class="feature_text">
                En algunas comunas, por ejemplo tienen un sistema que saca 2
                fotos y se envía para ser preparado en forma de set, para luego
                ser enviado al Juzgado de Policía Local.<br/><br/>El problema
                que se tiene con este proceso, es que el juzgado no acoge
                fotografías que están mal enfocadas o que están mal tipificadas
                debiendo desestimar las Multas. Así se pierde la Multa sacada y
                por ende no se puede seguir el proceso de cobro. <br/><br/>Con
                nuestra Aplicación LOGRAMOS LO SIGUIENTE:
              </div>
            </div>
            <div
                id="w-node-a43c7d98-1ce5-9675-aa9a-4f47b64ef89e-0568580c"
                class="_480"
            >
              <div class="div-block-7">
                <div class="div-block-6-copy _2">
                  <h1 class="heading">1</h1>
                </div>
                <div class="feature_text">
                  <strong class="bold-text-2"
                  >Lo nuevo de esta app es que no solo permite tomar fotos,
                    sino que también, graba un video de la infracción y del
                    entorno, esto último permite que el medio de prueba sé más
                    contundente y haya un menor margen de error por parte de los
                    inspectores.</strong
                  >
                </div>
              </div>
              <div class="div-block-7">
                <div class="div-block-6-copy">
                  <h1 class="heading">2</h1>
                </div>
                <div class="feature_text">
                  <strong class="bold-text-2"
                  >Otro factor importante es que las multas están
                    georreferenciadas, por lo que la infracción tiene una
                    geolocalización exacta de donde ocurrió.</strong
                  >
                </div>
              </div>
              <div class="div-block-7">
                <div class="div-block-6-copy">
                  <h1 class="heading">3</h1>
                </div>
                <div class="feature_text">
                  <strong class="bold-text-2"
                  >También se genera un registro de actividad sobre la multa,
                    por lo que la persona afectada puede revisar el estado en
                    línea del proceso en que está esa multa.</strong
                  >
                </div>
              </div>
              <div class="div-block-7">
                <div class="div-block-6-copy">
                  <h1 class="heading">4</h1>
                </div>
                <div class="feature_text">
                  <strong class="bold-text-2"
                  >Lo último y muy importante es que el mismo sistema genera
                    toda la documentación que será enviada al juzgado, solo con
                    un click el inspector puede imprimir la multa, así se tiene
                    un parte incluso físico.</strong
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section backgroud-2 wf-section">
      <div class="containerhtml">
        <div class="how_wrap">
          <h2 data-w-id="d4c715e4-5202-7357-adaf-89c37dc2ede9" class="h2">
            PIM <br/>Permite al Inspector
          </h2>
          <div class="w-layout-grid how_grid">
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Icon-metro-file-video.svg"
                    loading="lazy"
                    width="44"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Video y fotografía</h3>
              <div class="how_text">
                Grabar un video y tomar fotografías de la infracción y el
                entorno.
              </div>
            </div>
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Grupo-35.svg"
                    loading="lazy"
                    width="60"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Patentes</h3>
              <div class="how_text">
                Tomar la patente y obtener los datos del vehículo y su dueño.
              </div>
            </div>
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Icon-feather-send.svg"
                    loading="lazy"
                    width="42"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Guardar la data</h3>
              <div class="how_text">
                La tipificación de esta denuncia para su posterior proceso en el
                juzgado
              </div>
            </div>
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Icon-feather-edit.svg"
                    loading="lazy"
                    width="44"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Observaciones</h3>
              <div class="how_text">
                Permite al Inspector dejar observaciones sobre la infracción que
                se está visualizando.<br/>‍
              </div>
            </div>
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Icon-feather-printer.svg"
                    loading="lazy"
                    width="49"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Impresora</h3>
              <div class="how_text">
                La Aplicación esta conectada con una impresora portátil para
                imprimir el parte, que puede ser dejado en el vehículo del
                infractor
              </div>
            </div>
            <div class="how_block">
              <div class="div-block-6">
                <img
                    src="@/assets/images/Icon-feather-upload-cloud.svg"
                    loading="lazy"
                    width="44"
                    alt=""
                />
              </div>
              <h3 class="h3 centre">Respaldo online</h3>
              <div class="how_text">
                Está infracción podrá ser visualizada desde el Panel WEB de
                Inspectores, para luego ser procesada.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        data-w-id="a9cdeeaa-98ac-a965-65c5-bb327a8bb2e0"
        class="section wf-section"
    >
      <div class="containerhtml">
        <div class="feature_wrap">
          <div class="w-layout-grid feature_grid">
            <div
                id="w-node-_4eaa18bf-6989-7f86-d99e-31b09e8b93d4-0568580c"
                class="_480"
            >
              <h2 class="h2 how">P<font style="color: #befd9a;">unto </font>I<font style="color: #befd9a;">ngreso</font> M<font style="color: #befd9a;">unicipal</font> </h2>
              <div class="feature_text">
                Integran en un solo sistema de IA, diferentes tareas entre
                departamentos municipales que antes no era posible administrar.
                Además, Con esta  misma tecnología se podrían incorporar nuevas
                gestiones internas que el municipio necesita integrar, para
                hacer más eficiente los procesos de cualquier trámite municipal,
                beneficiando directamente a los ciudadanos de su comuna. 
              </div>
            </div>
            <div
                id="w-node-_0f07fd7f-39cc-efa0-f9c1-b30028744934-0568580c"
                class="feature_image-wrap"
            >
              <img
                  src="@/assets/images/632f1eba4a0d2f6efa68582a_1.png"
                  loading="lazy"
                  srcset="
                  ../../assets/images/632f1eba4a0d2f6efa68582a_1-p-500.png   500w,
                  ../../assets/images/632f1eba4a0d2f6efa68582a_1-p-800.png   800w,
                  ../../assets/images/632f1eba4a0d2f6efa68582a_1-p-1080.png 1080w,
                  ../../assets/images/632f1eba4a0d2f6efa68582a_1.png        1134w
                "
                  sizes="(max-width: 767px) 90vw, 567px"
                  alt=""
                  class="feature_image _1"
              /><img
                src="@/assets/images/632f1eba4a0d2f59fb685843_2.png"
                loading="lazy"
                srcset="
                  ../../assets/images/632f1eba4a0d2f59fb685843_2-p-500.png   500w,
                  ../../assets/images/632f1eba4a0d2f59fb685843_2-p-800.png   800w,
                  ../../assets/images/632f1eba4a0d2f59fb685843_2-p-1080.png 1080w,
                  ../../assets/images/632f1eba4a0d2f59fb685843_2.png        1134w
                "
                sizes="(max-width: 767px) 90vw, 567px"
                alt=""
                class="feature_image _2"
            /><img
                src="@/assets/images/632f1eba4a0d2fb0c7685838_3.png"
                loading="lazy"
                srcset="
                  ../../assets/images/632f1eba4a0d2fb0c7685838_3-p-500.png   500w,
                  ../../assets/images/632f1eba4a0d2fb0c7685838_3-p-800.png   800w,
                  ../../assets/images/632f1eba4a0d2fb0c7685838_3-p-1080.png 1080w,
                  ../../assets/images/632f1eba4a0d2fb0c7685838_3.png        1134w
                "
                sizes="(max-width: 767px) 90vw, 567px"
                alt=""
                class="feature_image _3"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        data-w-id="c3b3f129-cc55-0809-ae34-af08ca318d76"
        class="section wf-section"
    >
      <div class="containerhtml">
        <div class="feature_wrap _2">
          <div class="w-layout-grid feature_grid flip">
            <div
                id="w-node-c3b3f129-cc55-0809-ae34-af08ca318d7f-0568580c"
                class="feature_image-wrap"
            >
              <img
                  src="../../assets/images/632f1eba4a0d2f8e6f68583b_1.png"
                  loading="lazy"
                  srcset="
                  ../../assets/images/632f1eba4a0d2f8e6f68583b_1-p-500.png   500w,
                  ../../assets/images/632f1eba4a0d2f8e6f68583b_1-p-800.png   800w,
                  ../../assets/images/632f1eba4a0d2f8e6f68583b_1-p-1080.png 1080w,
                  ../../assets/images/632f1eba4a0d2f8e6f68583b_1.png        1204w
                "
                  sizes="(max-width: 767px) 90vw, 567px"
                  alt=""
                  class="feature_image _1"
              /><img
                src="../../assets/images/z92-1.png"
                loading="lazy"
                srcset="../../assets/images/z92-1.png 500w, ../../assets/images/z92-1.png 800w"
                sizes="(max-width: 767px) 90vw, 567px"
                alt=""
                class="feature_image _2"
            />
            </div>

            <div
                id="w-node-c3b3f129-cc55-0809-ae34-af08ca318d7a-0568580c"
                class="_480"
            >
              <h2 class="h2 how">Municipios más efecientes</h2>
              <div class="feature_text" style="text-align: justify;">
                Optimiza la gestión de los inspectores municipales. Mejora el
                Control Interno. Promueve una Fiscalización Municipal bien
                hecha. Recupera Recursos Perdidos. <br/>‍<br/>El Municipalismo
                Inteligente Los municipios están tomando una iniciativa
                importantísima en materia de tecnología. Hoy en día, ofrecen a
                sus ciudadanos herramientas que les permiten realizar sus tareas
                más sencillas con rapidez, eficacia y seguridad.<br/>‍<br/>Por
                ello, PIM es una de estas herramientas tecnológicas
                que están cambiando el mundo municipal. Gracias a ella, las
                personas que trabajan en las administraciones municipales pueden
                hacer su trabajo más rápidamente y eficientemente.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2 wf-section">
      <div class="w-container">
        <div class="div-block-8">
          <div class="div-block-9">
            <h1
                data-w-id="7c81de7d-44e3-0133-0d9e-bfc876cb6e1b"
                style="opacity: 1"
                class="heading-2"
            >
              Haz tu municipalidad <br/>mas eficiente hoy<br/>
            </h1>
            <a href="mailto:info@socialconnect.com" class="button w-button"
            >Descargar la App</a
            >
          </div>
          <div class="div-block-10">
            <img
                src="@/assets/images/612685230ff8fb8898837aac_image-cta-app-x-template.png"
                loading="lazy"
                style="opacity: 1"
                data-w-id="133470a4-c51c-27de-d390-678d73053d31"
                sizes="100vw"
                srcset="
                ../../assets/images/612685230ff8fb8898837aac_image-cta-app-x-template-p-500.png 500w,
                ../../assets/images/612685230ff8fb8898837aac_image-cta-app-x-template.png       789w
              "
                alt=""
                class="image-2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section wf-section">
      <div class="containerhtml">
        <div id="faqs" class="faq_container">
          <h2 data-w-id="5becaaa7-5702-910b-a08e-b6bebc919b3a" class="h2">
            Preguntas Frecuentes
          </h2>
          <div
              data-w-id="04f07ad9-a1a6-d8a3-af00-35bd73703e91"
              class="faq_wrapper"
          >
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">¿Nuestra App cumple con la ley?</h3>
                <img
                    src="@/assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  TODO NUESTRO SISTEMA se ha construido AL AMPARO DE LA LEY DEL
                  TRANSITO DEL MINISTERIO DE JUSTICIA NUM. 18.290 (Publicada en
                  el &quot;Diario Oficial&quot; N° 31.791, de 7 de Febrero de
                  1984).  Y por lo mismo su funcionalidad se ajusta a las normas
                  establecidas en ella y aplicables, en lo que fueren
                  compatibles, en aparcamientos y edificios de estacionamientos
                  y demás lugares de acceso público.<br/><br/>Carabineros de
                  Chile y los Inspectores Fiscales y Municipales son los
                  encargados de supervigilar el cumplimiento de las
                  disposiciones a que se refiere la presente ley, sus
                  reglamentos y las de transporte y tránsito terrestre que dicte
                  el Ministerio de Transportes y Telecomunicaciones o las
                  Municipalidades, debiendo denunciar  al Juzgado que
                  corresponda, las infracciones o contravenciones que se
                  cometan.<br/><br/>Los equipos de registro de infracciones
                  podrán consistir en películas cinematográficas, fotográficas,
                  fonográficas u otras formas de reproducción de la imagen y del
                  sonido y, en general, en medios aptos para producir fe.<br/><br/>El
                  reglamento del Ministerio de Transportes y Telecomunicaciones,
                  contempla los estándares técnicos de que los equipos que
                  permiten capturar las imágenes y videos, deben cumplir ciertas
                  normas  en resguardo de su confiabilidad y certeza y
                  establecer las condiciones en que han de ser usados para que
                  las imágenes u otros elementos de prueba que de ellos se
                  obtengan puedan servir de base para denunciar infracciones o
                  contravenciones”.<br/><br/><strong
                >CUMPLE CON ALTOS ESTANDARES DE CALIDAD Y EXIGENCIAS DE
                  OPERATIVIDAD.</strong
                >
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">
                  ¿El Inspector puede imprimir la Multa o Parte?
                </h3>
                <img
                    src="@/assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  Si nuestro sistema imprime y envia al correo que sea asignado
                  por el Juzgado de Policía Local de la comuna, para recibir las
                  multas de forma digital. Este documento que posee todos los
                  datos de la Multa que son necesarios para ser procesados por
                  el Juzgado de Policía Local de la comuna. 
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">¿Que datos tiene la Multa o Parte?</h3>
                <img
                    src="../../assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  El detalle de la Multa lleva la individualización del vehículo
                  y su dueño, datos de la multa, link al video grabado por el
                  inspector, fotos sacadas por el inspector al momento de
                  generar la denuncia, el voucher que se le dejó al vehículo (el
                  que se imprime en terreno) y el certificados de
                  inscripción.<br/><br/>Luego el Sistema permitirá a los
                  inspectores o personal del juzgado que tenga acceso, ir
                  modificando el estado de la multa, por ejemplo, si la multa ya
                  fue pagada o si fue desestimada, etc.<br/>
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">¿Que pasa si el inspector no tiene internet?</h3>
                <img
                    src="../../assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  La App permite ser utilizada sin conexión a internet, el parte
                  generado se almacena de forma local para luego ser ingresado
                  al sistema cuando la aplicación recupere el acceso a
                  internet.<br/>
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">¿Nuestro sistema protege al operador? <br/></h3>
                <img
                    src="../../assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  Si nuestra app esta conectada a una central de monitoreo,
                  donde la grabación del parte podrá ser visualizada en tiempo
                  real por otro operador (Cuando la app está trabajando con
                  acceso a internet). Esto puede prevenir o hacer evidente una
                  agresión que esté recibiendo el inspector al momento de
                  generar el parte<br/>
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
            <div class="faq_block">
              <div class="faq_top">
                <h3 class="h4">¿Como reviso si me hicieron un parte?</h3>
                <img
                    src="../../assets/images/down.svg"
                    loading="lazy"
                    alt="Chevron"
                    class="faq_arrow"
                />
              </div>
              <div class="faq_bottom">
                <div class="faq_text">
                  En la impresión que se deja al infractor se incluye un código
                  qr (link) donde este podrá visualizar el estado del parte
                  (luego de haber sido individualizado por el inspector)<br/>
                </div>
              </div>
              <div class="faq_divider"></div>
            </div>
          </div>
          <div
              id="contact"
              data-w-id="49ee61b8-d845-9f60-e3f7-0203346113c2"
              class="contact"
          >
            <h2 class="h3 centre contact-header">¿Tienes otras preguntas?</h2>
            <a @click="changeDrawer" class="button w-button">Contactanos</a>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        v-model="dialog"
    >

      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="green"
              dark
          >Revisar Multa</v-toolbar>
          <v-card-text>
            <div class="ma-2">
              <v-text-field v-model="multa" label="ID Multa" required autofocus></v-text-field>
            </div>
            </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                text
                @click="dialog.value = false"
            >Cerrar</v-btn>
            <v-btn
                text
                @click="goToMulta"
                color="green"
            >Revisar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {getOrderStatusByToken, startPaymentPlan,} from "../helpers/api/planes";

export default {
  name: "Home",
  components: {},
  data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
    dialog: false,
    multa: '',
  }),
  mounted() {
    // if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
    //   this.getOrderStatus(this.$route.query.token);
    // }
    if(this.$route.query && this.$route.query.id) {
      console.log(this.$route.query.id)
      this.$router.push({ name: 'Multa', query: { id: this.$route.query.id } })
    }
  },
  methods: {
    goToMulta() {
      this.$router.push({ name: 'Multa', query: { id: this.multa } })
    },
    changeDrawer() {
      // changeDrawer
      this.$store.commit("setChangeDrawer");
    },
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
            planPayment.flowData &&
            planPayment.flowData.url &&
            planPayment.flowData.token
        ) {
          window.location.href = planPayment.flowData.url + "?token=" + planPayment.flowData.token;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({order_token});
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.media
              : "",
          fecha: status.orderStatus.paymentData
              ? status.orderStatus.paymentData.date
              : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: {open: true, data},
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/transita-seguro.webflow.css";

/* [data-wf-bgvideo-fallback-img] {
  display: none;
}

.theme--light.v-application {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
} */
</style>